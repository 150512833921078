import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import Heading from "../../../../components/heading"
import FloatContainer from "../../../../components/float-container"
import Image from "../../../../components/image"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import Clearfix from "../../../../components/clearfix"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query DichterwerkstattQuery {
      musenalmanach: file(relativePath: { eq: "kurse/musenalmanach.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/manuskript" />
      }
      navigation={navigation}
    >
      <Seo title="Ein Blick in die Dichterwerkstatt" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 2
            </Heading>
            <Heading as="h2" level={2}>
              Ein Blick in die Dichterwerkstatt
            </Heading>
          </Stack>
          <Paragraph>
            Hölderlins Gedicht ›Andenken‹ entstand im Jahr 1803. Zu dieser Zeit
            schuf Hölderlin seine großen Hymnen und Vaterländischen Gesänge, die
            in der deutschen Literatur unnachahmbar geblieben sind.
          </Paragraph>
          <Clearfix>
            <FloatContainer width="30%">
              <Image
                image={data.musenalmanach.childImageSharp.gatsbyImageData}
                alt="Titelbild von Leo von Seckendorfs Musenalmanach"
                caption="Leo von Seckendorfs Musenalmanach"
              />
            </FloatContainer>
            <Paragraph mb={[6, 12]}>
              Viele der Gedichte aus dieser Zeit beschäftigen sich mit dem Thema
              der Reise: So etwa das Fragment ›Der Adler‹, das dem Adlerflug bis
              nach Griechenland folgt, das Gedicht über die Südsee-Insel
              ›Tinian‹, das Donau-Gedicht ›Der Ister‹ und das Gedicht
              ›Griechenland‹.
            </Paragraph>
            <Paragraph mb={[6, 12]}>
              Veröffentlicht wurde ›Andenken‹ allerdings erst 5 Jahre später in
              Leo von Seckendorfs Musenalmanach auf das Jahr 1808 unter der
              Rubrik ›Vermischte Gedichte‹. Sein Gedicht ›Der Rhein‹ und seine
              Hymne über die griechische Insel ›Patmos‹ erschienen im selben
              Sammelband. Inzwischen hatte Hölderlin nach einer 231-tägigen
              psychiatrischen Behandlung im Tübinger Klinikum in der Familie des
              Schreinermeisters Ernst Zimmer im Turm am Neckar eine Bleibe
              gefunden und verfasste Gedichte, die rhythmisch und thematisch
              ganz anders gestaltet waren.
            </Paragraph>
            <Paragraph>
              In diesem Kapitel werfen wir einen Blick in die Dichterwerkstatt.
              Wir verfolgen, wie Hölderlin an seinem Gedicht ›Andenken‹
              gearbeitet hat und ihm seinen ganz besonderen Sound verpasst hat.
              Du lernst, wie man das Gedicht sprechen, klopfen und performen,
              wie man dazu gehen und wie man es in eigene bewegte Bilder
              übersetzen kann.
            </Paragraph>
          </Clearfix>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
